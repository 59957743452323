import { GetServerSidePropsContext, InferGetServerSidePropsType, NextPage } from "next";
import { Anchor, Box, Center, Divider, Stack, Title, useMantineTheme } from "@mantine/core";
import { useRouter } from "next/router";
import { withIronSessionSsr } from "iron-session/next";
import { ironOptions } from "utils/config";
import { Ring } from "@uiball/loaders";
import { TextInput, PasswordInput } from "components/Input";
import Image from "next/image";
import Link from "next/link";
import { prisma } from "utils/prisma";
import { GoogleLogin } from "@react-oauth/google";
import { useMediaQuery } from "@mantine/hooks";
import { FEATURE_FLAGS } from "utils/feature-flags";
import { trpc } from "utils/trpc";
import { emailSchema, passwordMaxSchema } from "shared/schemas/auth";
import { useZodForm } from "hooks/useZodForm";
import { z } from "zod";
import BaseButton from "components/v2/ButtonV2/BaseButton";
import TextInputV2 from "components/v2/Input/TextInputV2";

const SignIn: NextPage<InferGetServerSidePropsType<typeof getServerSidePropsFunction>> = ({
  inviteId,
  email,
}) => {
  const router = useRouter();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const signIn = trpc.auth.signIn.useMutation({
    onSuccess: () => router.push("/"),
  });

  const form = useZodForm(
    z.object({
      email: emailSchema.default(email || ""),
      password: passwordMaxSchema,
    }),
  );

  const signUpLink = `/signup${
    inviteId ? `?inviteId=${inviteId}&email=${encodeURIComponent(email!)}` : ""
  }`;

  return (
    <Center style={{ height: "100vh" }}>
      <Box
        sx={(theme) => ({
          width: "400px",
          [theme.fn.smallerThan("xs")]: {
            maxWidth: "300px",
          },
        })}
      >
        <Center mb="xl">
          <Stack spacing="xl">
            <Center>
              <Image src="/images/signup-logo.png" width={44} height={44} alt="rocket" />
            </Center>
            {FEATURE_FLAGS.GOOGLE_SIGNIN && <Title order={3}>Hey, welcome back!</Title>}
          </Stack>
        </Center>
        {FEATURE_FLAGS.GOOGLE_SIGNIN && (
          <>
            <GoogleLogin
              text="signin_with"
              width={isMobile ? "300px" : "400px"}
              locale="en_US"
              onSuccess={({ credential }) => {
                signIn.mutate({
                  token: credential,
                  ...(inviteId && { inviteId }),
                });
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <Divider
              className="text-gray-500"
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />
          </>
        )}

        <form
          className="flex flex-col gap-4 justify-center"
          onSubmit={form.onSubmit((values) => signIn.mutate({ ...values, inviteId }))}
        >
          <TextInputV2
            required
            label="Email"
            type="email"
            size="lg"
            radius={15}
            labelProps={{ size: "md", mb: 2 }}
            disabled={!!inviteId}
            {...form.getInputProps("email")}
          />

          <PasswordInput
            required
            label="Password"
            labelProps={{ size: "md", mb: 0 }}
            size="lg"
            radius={15}
            {...form.getInputProps("password")}
          />
          <div className="flex justify-start">
            <p className="text-sm m-0 mr-1">Forgot password? No worries! </p>
            <Link href="/forgot-password" passHref legacyBehavior>
              <Anchor
                style={{
                  textDecoration: "underline",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Reset password
              </Anchor>
            </Link>
          </div>
          <BaseButton
            type="submit"
            disabled={signIn.isLoading}
            className="w-full"
            leftSlot={signIn.isLoading ? () => <Ring size={24} /> : undefined}
          >
            {signIn.isLoading ? "Signing in" : "Sign in"}
          </BaseButton>
          <Center>
            <p className="text-sm m-0 mr-2">Not a customer yet? </p>
            <Link
              href="https://meetings-eu1.hubspot.com/jbonilla/querio-demo"
              passHref
              legacyBehavior
            >
              <Anchor
                style={{
                  textDecoration: "underline",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Book a demo
              </Anchor>
            </Link>
          </Center>
        </form>
      </Box>
    </Center>
  );
};

export default SignIn;

async function getServerSidePropsFunction({ req, query }: GetServerSidePropsContext) {
  const querySchema = z
    .object({
      inviteId: z.string().cuid().optional(),
      email: emailSchema.optional(),
    })
    .transform(({ inviteId, ...d }) => ({ ...d, inviteId: inviteId }));
  const { inviteId, email } = querySchema.parse(query);
  // if the user is already logged in, redirect to the home page
  if (req.session?.user?.id) {
    // accept invite if there's one
    if (inviteId && email && req.session.user.email === email) {
      const invite = await prisma.invite.findFirst({
        where: { id: inviteId, email },
      });

      if (invite) {
        const role = await prisma.workspaceRole.create({
          data: {
            role: invite.role,
            workspace: {
              connect: { id: invite.workspaceId },
            },
            user: {
              connect: { id: req.session.user.id },
            },
          },
        });

        if (!!role) {
          await prisma.invite.delete({ where: { id: inviteId } });
        }
      }

      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    } else if (!inviteId && !email) {
      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    }
  }

  return {
    props: { inviteId, email },
  };
}

export const getServerSideProps = withIronSessionSsr(getServerSidePropsFunction, ironOptions);
